/* 
 * homepage - global css
 * @author {{author}}
 * @version {{version}}
 */

.homepage {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
}

.homepage__button {
    background-color: $white;
    border:           1px solid $black;
    color:            $black;
    display:          block;
    margin-bottom:    4%;
    padding:          pxToRem(15);
    text-align:       center;
    text-decoration:  none;
    width:            48%;

    svg {
        height: pxToRem(40);
        width: pxToRem(40);
        display: block;
        margin: 0 auto pxToRem(10) auto;
        transition: transform .5s ease-in-out;
    }

    &:hover,
    &:focus {
        svg {
            transform: rotate(-15deg) scale(1.2);
        }
    }
}