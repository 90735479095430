/* 
 * bullet-drop - global css
 * @author {{author}}
 * @version {{version}}
 */

.bullet-drop__back {
    background-color: $blue;
    border-radius:    pxToRem(20) 0 0 pxToRem(20);
    border:           0;
    cursor:           pointer;
    height:           pxToRem(55);
    padding:          pxToRem(10) pxToRem(30) pxToRem(10) pxToRem(10);
    position:         absolute;
    right:            0;
    top:              pxToRem(7);
    transition:       all .5s ease;

    svg {
        height: pxToRem(35);
        width:  pxToRem(35);
    }

    &--hidden {
        transform: translateX(100%);
    }
}

.bullet-drop {
    &[data-show="list"] {
        .bullet-drop__list { display: block; }
        .bullet-drop__table { display: none; }
    }
    &[data-show="table"] {
        .bullet-drop__list { display: none; }
        .bullet-drop__table { display: block; }
    }
}

.bullet-drop__form-add-table-wrapper {
    display:         flex;
    justify-content: space-between;
    margin-bottom:   pxToRem(10);
}

.bullet-drop__form-add-table {
    flex-basis: 75%;
}
.bullet-drop__add-table {
    background-color: $darkGreen;
    border:           1px solid $darkGreen;
    color:            $white;
    cursor:           pointer;
    flex-basis:       20%;
    font-size:        pxToRem(13);
}
.bullet-drop__form-label {
    display:   block;
    font-size: pxToRem(12);
}

.bullet-drop__form-input {
    border:  1px solid $black;
    padding: pxToRem(10);
    width:   100%;
}
.bullet-drop__table-clicktype {
    max-width: pxToRem(50);
}

.bullet-drop__list-item-wrapper {
    display: flex;
    justify-content: space-between;

    &[data-hidden="true"] {display: none;}
}

.bullet-drop__list-item {
    border:           1px solid $black;
    border-left:      pxToRem(50) solid $black;

    background-color: $white;
    color:            $black;
    cursor:           pointer;
    flex-basis:       75%;
    margin-bottom:    pxToRem(10);
    padding:          pxToRem(15);
    position:         relative;
    text-align:       left;

    svg {
        height:   pxToRem(36);
        left:     -43px;
        position: absolute;
        top:      5px;
        width:    pxToRem(36);
        z-index:  200;

        path {
            fill: $white;
        } 
    }
}

.bullet-drop__list-item-remove {
    background-color: $darkRed;
    border:           1px solid $darkRed;
    color:            $white;
    cursor:           pointer;
    flex-basis:       20%;
    font-size:        pxToRem(13);
    margin-bottom:    pxToRem(10);
    padding:          pxToRem(10);
}

.bullet-drop__table-table {
    background-color: $white;
    border-collapse: collapse;
    border:           1px solid $black;
    width:            100%;

    tbody tr.bullet-drop__table-row:hover,
    tbody tr.bullet-drop__table-row:focus {
        background-color: $green;
    }

    td,
    th {
        border:     1px solid $black;
        padding:    pxToRem(5);
        text-align: center;
        width:      21.25%;
    }
    td:last-child,
    th:last-child {
        width: 15%;
    }

    th {
        font-size: pxToRem(12);
    }
}

.bullet-drop__table-name {
    border:           1px solid transparent;
    border-bottom:    1px solid $black;
    
    background-color: rgba(255,255,255,0.35);
    margin-bottom:    pxToRem(7);
    padding:          pxToRem(10);
    text-align:       center;
    transition:       all .5s ease;
    width:            80%;


    &:hover,
    &:focus {
        background-color: $white;;
        border: 1px solid $black;
    }
}

.bullet-drop__table-input {
    -webkit-appearance: none;
    -moz-appearance:    textfield;
    border:             1px solid $black;
    padding:            pxToRem(5);
    width:              100%;
}

.bullet-drop__table-remove,
.bullet-drop__table-add {
    background-color: transparent;
    border:           0;
    cursor:           pointer;
    padding:          pxToRem(7) pxToRem(5) pxToRem(5);
    width:            100%;

    svg {
        height:         pxToRem(20);
        pointer-events: none;
        width:          pxToRem(20);
    }
}

.bullet-drop__table-desc {
    font-size:  pxToRem(10);
    height:     pxToRem(35);
    opacity:    0.5;
    padding:    pxToRem(10);
    resize:     none;
    transition: all .5s ease;
    width:      100%;

    &:hover,
    &:focus {
        height:  pxToRem(120);
        opacity: 1;
    }

}