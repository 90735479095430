// Convert a pixel value to EM units
@function pxToEm($pixel) {
    @return $pixel/$base-font-pixel + em;
}

// Convert a pixel value to REM units
@function pxToRem($pixel) {
    @return $pixel/$base-font-pixel + rem;
}

// Clearfix
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear:   both;
    }
}

// Opacity
@mixin opacity($opacity) {
    opacity:     $opacity;
    $opacity-ie: $opacity * 100;
    filter:      alpha(opacity = $opacity-ie); //IE8
}

// Gradient
@mixin gradient($start-color, $end-color, $orientation:vertical) {
    background: $start-color;
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}
