// Fonts
$font-weight-normal:   500;
$font-weight-semibold: 600;
$font-weight-bold:     700;

$base-font-weight: 	   $font-weight-normal;
$base-font-pixel:      16; 
$base-letter-spacing:  pxToRem(-0.5);
$base-font-family:     'Open Sans', sans-serif;
$base-font-color:      #222222;
$base-line-height:     1.6;

// Colors
$white: #fff;
$black: #000;
$bg:    #c9c9c9;

$gray-1:    #333333;
$gray-2:    #666666;
$gray-3:    #999999;
$gray-4:    #cccccc;

$lightGreen: #98ec98;
$green:  	 #5cb85c;
$darkGreen:  #3e853e;
$lightRed: 	 #f77772;
$red: 		 #d9534f;
$darkRed: 	 #973a37;
$lightBlue:  #5bc0de;
$blue:	 	 #428bca;
$darkBlue:	 #235988;

// Grid
$grid-gap-size:      pxToRem(8);
$grid-width-percent: 85%;
$grid-max-width-px:  1170;