$cpfTransparent: rgba(0,0,0,0);
$cpfPointColor: rgba(255,0,0,0.75);
$cpfCenterPointColor: rgba(0,255,0,1);
$cpfAddPointColor: #30c830;

body.cpf__body {
    overscroll-behavior: contain;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

.cpf {
    &__field {
        background-repeat: no-repeat;
        position: absolute;
        z-index: 199;
        top: pxToRem(70);
        bottom: pxToRem(35);
        left: 0;
        right: 0;
    }

    &__point {
        width: pxToRem(40);
        height: pxToRem(40);
        background-color: $cpfTransparent;
        border: pxToRem(10) solid $cpfPointColor;
        border-radius: 50%;
        transform: translate(-50%, pxToRem(-150));
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 400;

        &--count {
            font-size: pxToRem(12);
            position: absolute;
            bottom: pxToRem(18);
            right: pxToRem(12);
        }
    }

    &__drag {
        position: absolute;
        top: pxToRem(-40);
        left: pxToRem(-40);
        background-color: $cpfTransparent;
        color: white;
        height: pxToRem(100);
        width: pxToRem(100);
    }


    &__center {
        width: pxToRem(20);
        height: pxToRem(20);
        background-color: $cpfCenterPointColor;
        border-radius: 50%;
        transform: translate(-50%, pxToRem(-140));
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 500;
    }

    &__add-point {
        position: fixed;
        bottom: pxToRem(60);
        left: pxToRem(20);
        width: pxToRem(75);
        height: pxToRem(75);
        border-radius: 50%;
        border: 0;
        font-size: pxToRem(48);
        background: $cpfAddPointColor;
        color: #fff;
        font-weight: bold;
        z-index: 800;
    }

    &__move,
    &__file-label {
        border: 1px solid #222;
        background-color: $white;
        display: flex;
        width: pxToRem(36);
        height: pxToRem(36);
        justify-content: center;
        align-items: center;
        border-radius: pxToRem(5);
        position: fixed;
        z-index: 700;

        &--up {
            bottom: pxToRem(100);
            right: pxToRem(60);
        }
        &--right {
            bottom: pxToRem(60);
            right: pxToRem(20);
        }
        &--down {
            bottom: pxToRem(60);
            right: pxToRem(60);
        }
        &--left {
            bottom: pxToRem(60);
            right: pxToRem(100);
        }
        &--in {
            bottom: pxToRem(100);
            right: pxToRem(100);
        }
        &--out {
            bottom: pxToRem(100);
            right: pxToRem(20);
        }
    }

    &__file {
        position: absolute;
        left: -1000px;
    }

    &__file-label {
        width: pxToRem(48);
        height: pxToRem(48);
        bottom: pxToRem(75);
        left: pxToRem(120);
        right: auto;
    }
}