/* 
 * fkd - global css
 * @author {{author}}
 * @version {{version}}
 */

.fkd__calc {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
}

.fkd__calc-field {
    flex-basis: 25%;

    &--reset {
        flex-basis: 15%;
    }
}

.fkd__input {
    -webkit-appearance: none;
    -moz-appearance:    textfield;
    border:             1px solid $black;
    padding:            pxToRem(10);
    width:              100%;

    &[disabled] {
        background-color: $green;
        border:           1px solid $gray-1;
        color:            $white;
        font-weight:      bold;
        text-align:       center;
    }
}

.fkd_label {
    font-size: pxToRem(12);
}

.fkd__reset {
    background-color: $gray-2;
    border-radius:    pxToRem(20);
    border:           1px solid $gray-1;
    color:            $white;
    cursor:           pointer;
    height:           100%;
    width:            100%;
}
