/* 
 * header - tablet css
 * @author {{author}}
 * @version {{version}}
 */

.header {
    background-color: $white;
    box-shadow:       0 pxToRem(10) pxToRem(10) rgba(0,0,0,0.2);
    left:             0;
    position:         fixed;
    right:            0;
    top:              0;
    width:            100%;
    z-index:          200;

    .header__heading {
        margin:  0;
        padding: 0;
    }

    .header__link {
        @extend .content__wrapper;
        display: block;
        padding: pxToRem(15) 0;

        &:hover,
        &:focus {
            svg {
                transform: translateX(-150%);                
            }
        }

        svg {
            display:    block;
            height:     pxToRem(40); 
            margin:     0 auto;
            transition: all .5s ease-out;
            width:      pxToRem(40);
        }
    }
   
}