/* 
 * desktop grid css
 * @version {{version}}
 */
.col-100\@lg { flex-basis: 100%; max-width: 100%; }
.col-95\@lg { flex-basis: 95%; max-width: 95%; }
.col-90\@lg { flex-basis: 90%; max-width: 90%; }
.col-85\@lg { flex-basis: 85%; max-width: 85%; }
.col-80\@lg { flex-basis: 80%; max-width: 80%; }
.col-75\@lg { flex-basis: 75%; max-width: 75%; }
.col-70\@lg { flex-basis: 70%; max-width: 70%; }
.col-65\@lg { flex-basis: 65%; max-width: 65%; }
.col-60\@lg { flex-basis: 60%; max-width: 60%; }
.col-55\@lg { flex-basis: 55%; max-width: 55%; }
.col-50\@lg { flex-basis: 50%; max-width: 50%; }
.col-45\@lg { flex-basis: 45%; max-width: 45%; }
.col-40\@lg { flex-basis: 40%; max-width: 40%; }
.col-35\@lg { flex-basis: 35%; max-width: 35%; }
.col-33\@lg { flex-basis: 33.333%; max-width: 33.333%; }
.col-30\@lg { flex-basis: 30%; max-width: 30%; }
.col-25\@lg { flex-basis: 25%; max-width: 25%; }
.col-20\@lg { flex-basis: 20%; max-width: 20%; }
.col-15\@lg { flex-basis: 15%; max-width: 15%; }
.col-10\@lg { flex-basis: 10%; max-width: 10%; }
