/* 
 * footer - desktop css
 * @author {{author}}
 * @version {{version}}
 */

.footer {
    background-color: $white;
    bottom:           0;
    box-shadow:       0 pxToRem(-10) pxToRem(10) rgba(0,0,0,0.2);
    color:            $black;
    font-size:        pxToRem(10);
    left:             0;
    padding:          pxToRem(10);
    position:         absolute;
    right:            0;
    width:            100%;
}

.footer__inner {
    @extend .content__wrapper;
    overflow: hidden;
}

.footer__left {
    float: left;
}

.footer__right {
    float: right;
}

.footer_link {
    color: $black;
}