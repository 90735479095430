.unit-conversion__calc {
    display: flex;
}
.unit-conversion__calc-col {
    flex-basis: 40%;

    .unit-conversion__calc &:last-child {
        flex-basis: 20%;
    }
}

.unit-conversion__select {
    padding: pxToRem(10);
    margin-bottom: pxToRem(10);
    width: 95%;

}

.unit-conversion__input {
    padding: pxToRem(10);
    width: 95%;

    &[disabled] {
        @extend .fkd__input[disabled];
    }
}

.unit-conversion__reset {
    @extend .fkd__reset;
    width: 90%;
    margin-left: 10%;
}