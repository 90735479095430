.bullet-drop__import-button,
.bullet-drop__export-button,
.bullet-drop__export-all-button {
    background-color: $darkGreen;
    border:           1px solid $darkGreen;
    color:            $white;
    cursor:           pointer;
    font-size:        pxToRem(12);
    padding:          pxToRem(15);
    width:            100%;

    &:disabled {
        background-color: $gray-2;
        border:           1px solid $gray-1;
        color:            $white;
        cursor:           not-allowed;
    }
}

.bullet-drop__import-json,
.bullet-drop__export-json,
.bullet-drop__export-all-json {
    font-size:  pxToRem(10);
    height:     pxToRem(60);
    opacity:    0.5;
    padding:    pxToRem(7);
    resize:     none;
    transition: opacity .5s ease;
    width:      100%;

    &:hover,
    &:focus {
        opacity: 1;
    }
}
