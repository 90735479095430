/* 
 * units-table - global css
 * @author {{author}}
 * @version {{version}}
 */

.units-table__table {
    border-collapse: collapse;
    border: 1px solid $black;
    width:  100%;
    background-color: $white;
    margin-bottom: 20px;

    &:hover {
        cursor: default;
    }

    td,
    th {
        border: 1px solid $black;
        text-align: center;
        padding: pxToRem(7);
    }

    tbody th,
    thead tr:nth-child(2) th {
        font-size: pxToRem(13);
    }

    tbody tr:hover td {
        background-color: $green;
        color: $white;

        &.units-table__marked {
            background-color: $blue;
            color: $white;
        }
    }

    .units-table__marked {
        background-color: $blue;
        color: $white;
    }

}