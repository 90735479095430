/* 
 * global css
 * @version {{version}}
 */

// apply a natural box layout model to all elements, but allowing components to change
html {
    box-sizing: border-box;
    height:     100%;
}
*, *:before, *:after {
    box-sizing: inherit;
}

// Global settings
body {
    //min-height:     calc(100% - #{pxToRem(70)});
    background-color: $bg;
    color:            $base-font-color;
    font-family:      $base-font-family;
    font-size:        $base-font-pixel+px;
    font-weight:      $base-font-weight;
    letter-spacing:   $base-letter-spacing;
    line-height:      $base-line-height;
    min-height:       100%;
    padding-top:      pxToRem(70);
    position:         relative;
}

.sr-only {
    border:    0;
    clip:      rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height:    1px;
    margin:    -1px;
    overflow:  hidden;
    padding:   0;
    position:  absolute;
    width:     1px;
    word-wrap:  normal !important;
}

.content__wrapper {
    margin:    0 auto;
    max-width: pxToRem($grid-max-width-px);
}

.content {
    //height:     calc(100vh - #{pxToRem(100)});
    //left:       0;
    overflow-x: auto;
    overflow-y: auto;
    padding:    pxToRem(30) pxToRem(20);
    //position:   fixed;
    //right:      0;
    //top:        pxToRem(70);
    width:      100%;
    //margin-top: 70px;

    .content__inner {
        @extend .content__wrapper;
        padding-bottom: pxToRem(30);
    }
}

.page-header {
    font-size:      1rem;
    margin:         0 0 pxToRem(20) 0;
    padding:        0;
    position:       relative;
    text-align:     center;
    text-transform: uppercase;
    
    &::after {
        background-color: $black;
        bottom:           -.2rem;
        content:          '';
        height:           1px;
        left:             50%;
        position:         absolute;
        transform:        translateX(-50%);
        width:            pxToRem(50);
    }
}

.page-subheader {
    font-size: 1rem;
    position:  relative;

    &::after {
        background-color: $black;
        bottom:           -.2rem;
        content:          '';
        height:           1px;
        left:             0;
        position:         absolute;
        width:            pxToRem(20);
    }
}

.box {

    &-info,
    &-warning,
    &-success {
        border-left-style: solid;
        border-left-width: pxToRem(35);
        font-size:         pxToRem(13);
        padding:           pxToRem(7);
        position:          relative;

        &::before {
            background-repeat: no-repeat;
            background-size:   pxToRem(20) pxToRem(20);
            content:           '';
            display:           block;
            float:             left;
            height:            pxToRem(20);
            left:              pxToRem(-27);
            margin:            0 pxToRem(8) pxToRem(8) 0;
            position:          absolute;
            top:               pxToRem(7);
            width:             pxToRem(20);
        }
    }

    &-info {
        background-color:  $blue;
        border-left-color: $darkBlue;
        color:             $white;

        &::before { background-image: url('images/info.svg'); }
    }
    &-warning {
        background-color:  $red;
        border-left-color: $darkRed;
        color:             $white;

        &::before { background-image: url('images/alert.svg'); }
    }
    &-success {
        background-color:  $green;
        border-left-color: $darkGreen;
        color:             $white;

        &::before { background-image: url('images/tick.svg'); }
    }
}